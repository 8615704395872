import React from 'react'
// import ProgressiveImage from '../ProgressiveImage'
import styled from 'styled-components'
// import CategoryLandingImage from '../../assets/images/tease/fw20-palace-cp-statement-m.png'
import PhoneOutline from '../../assets/images/phone.svg'

const Wrap = styled.div `
  flex: .5;
  position: relative;
`
const Phone = styled.div `
position: absolute;
top: 0;
left: 0;
height: calc(90vh - 22rem + 30px);
width: 100%;
  & img{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    display: block;
  }
`

function MobileFrame() {

  return <Wrap>
  <Phone>
    <img src={PhoneOutline} alt="iPhone screen" />
  </Phone>
  </Wrap>;
}

export default MobileFrame;
