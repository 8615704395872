import React, {PureComponent} from 'react'
import styled, {createGlobalStyle} from 'styled-components'
import {NavLink} from 'react-router-dom'
import anime from 'animejs'
import Tilt from 'react-tilt'
import '../../assets/styles/splash.sass'
import LogoSVG from '../../assets/images/logo.svg'
import AdiPalace from '../../assets/images/adi-palace.png'

const HomeStyle = createGlobalStyle `
  .sidebar {
    display: none;
  }
  .content .content-head {
    max-width: none;
    width: 100%;
  }
  body {
    background: none;
  }
`

const ContentHead = styled.div `
  height: 100%;
  display: flex;
  flex-direction: column;
  & div {
    flex: 1;
  }
`

const Titles = styled.div `
  font-size: 4.2rem;
  line-height: 1.15;
  letter-spacing: -.66px;
  & h1{
    font-size: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    line-height: inherit;
    margin: 0;
  }
  & img {
    max-width: 80px;
  }
`

const HomeBox = styled.div `
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  }
    & > div {
    display: flex;
    flex-basis: calc(50%);
    flex-direction: column;
    &:nth-child(2), :nth-child(4){
      align-items: flex-end;
      text-align: right;
    }
    &:nth-child(3), :nth-child(4){
      justify-content: flex-end;
    }
  }
`

const Logo = styled.div `
  max-width: 100px;
  width: 8rem;
  z-index: 9;
`


class NewNewHome extends PureComponent {

  navigate() {
    var DOM = {};
    DOM.intro = document.querySelector('.content--intro')
    DOM.shape = DOM.intro.querySelector('svg.shape')
    DOM.path = DOM.shape.querySelector('path')

    DOM.shape.style.transformOrigin = '50% 0%';

    anime({
      targets: DOM.shape,
      scaleY: [
        {
          value: [
            0.8, 1.8
          ],
          duration: 550,
          easing: 'easeInQuad'
        }, {
          value: 1,
          duration: 550,
          easing: 'easeOutQuad'
        }
      ]
    });

    anime({targets: DOM.intro, duration: 1100, easing: 'easeInOutSine', translateY: '-200vh'});

    anime({targets: DOM.path, duration: 1100, easing: 'easeOutQuad', d: DOM.path.getAttribute('pathdata')});
  }

  constructor(props) {
    super(props);
    this.state = {
      ldna: false,
      tt: false,
      pr: false,
      cos: false
    }
  }

  onMouseEnterLdna = e => {
    this.setState({ldna: true})
  }
  onMouseEnterTt = e => {
    this.setState({tt: true})
  }
  onMouseEnterCos = e => {
    this.setState({cos: true})
  }
  onMouseEnterPr = e => {
    this.setState({pr: true})
  }

  onMouseLeave = e => {
    this.setState({ldna: false, tt: false, pr: false, cos: false})
  }

  componentDidMount() {

  
  }

  render() {

    const style = this.state.ldna || this.state.tt || this.state.cos || this.state.pr
      ? {
        backgroundColor: "rgba(228,54,36,.9)"
        /* backgroundColor: "#622ae5",
        background: "linear-gradient(142deg, #5d52a2, #622ae5 100%)" */
      } : null

    return (<> < div className = 'content' > <HomeStyle/>
    <ContentHead>
      <HomeBox>
        <div>
          <Logo>
            <a href='https://rna-rna.com' rel='noopener noreferrer' target='_blank'>
              <img src={LogoSVG} alt='R.N.A logo'/>
            </a>
          </Logo>
        </div>
        <Titles>
          <img src={AdiPalace} alt="adidas Palace logo"/>
        </Titles>
        <Titles>
          adidas Originals<br/>Digital Marketing
        </Titles>
        <Titles>
          <h1>Palace<br/>Fall / Winter 2020
          </h1>
        </Titles>
      </HomeBox>
    </ContentHead>

  </div>

  <HomeStyle/>
  <div className="full-scr" style={style}></div>
  <Tilt options={{
      scale: 1,
      easing: "cubic-bezier(.03,.98,.52,.99)",
      max: 33,
      transition: true,
      glare: true
    }} style={{
      zIndex: '2',
      position: 'fixed'
    }}>
    <div className="scroll-menu">
      <NavLink exact="exact" to="/tease" className="scroll-left">
        <div className="container container__tease" onMouseEnter={this.onMouseEnterLdna} onMouseLeave={this.onMouseLeave}>
          <div className="block block__tease">
            <h2>Tease</h2>
          </div>
          <div className="block block__tease">
            <h2>Tease</h2>
          </div>
          <div className="block block__tease">
            <h2>Tease</h2>
          </div>
          <div className="block block__tease">
            <h2>Tease</h2>
          </div>
          <div className="block block__tease">
            <h2>Tease</h2>
          </div>
          <div className="block block__tease">
            <h2>Tease</h2>
          </div>
          <div className="block block__tease">
            <h2>Tease</h2>
          </div>
          <div className="block block__tease">
            <h2>Tease</h2>
          </div>
          <div className="block block__tease">
            <h2>Tease</h2>
          </div>
          <div className="block block__tease">
            <h2>Tease</h2>
          </div>
          <div className="block block__tease">
            <h2>Tease</h2>
          </div>
          <div className="block block__tease">
            <h2>Tease</h2>
          </div>
          <div className="block block__tease">
            <h2>Tease</h2>
          </div>
          <div className="block block__tease">
            <h2>Tease</h2>
          </div>
        </div>
      </NavLink>

      <NavLink exact="exact" to="/educate" className="scroll-right">
        <div className="container container__educate" onMouseEnter={this.onMouseEnterTt} onMouseLeave={this.onMouseLeave}>
          <div className="block block__educate">
            <h2>Educate</h2>
          </div>
          <div className="block block__educate">
            <h2>Educate</h2>
          </div>
          <div className="block block__educate">
            <h2>Educate</h2>
          </div>
          <div className="block block__educate">
            <h2>Educate</h2>
          </div>
          <div className="block block__educate">
            <h2>Educate</h2>
          </div>
          <div className="block block__educate">
            <h2>Educate</h2>
          </div>
          <div className="block block__educate">
            <h2>Educate</h2>
          </div>
          <div className="block block__educate">
            <h2>Educate</h2>
          </div>
          <div className="block block__educate">
            <h2>Educate</h2>
          </div>
          <div className="block block__educate">
            <h2>Educate</h2>
          </div>
          <div className="block block__educate">
            <h2>Educate</h2>
          </div>
          <div className="block block__educate">
            <h2>Educate</h2>
          </div>
          <div className="block block__educate">
            <h2>Educate</h2>
          </div>
        </div>
      </NavLink>

      <NavLink exact="exact" to="/launch" className="scroll-left">
        <div className="container container__launch" onMouseEnter={this.onMouseEnterCos} onMouseLeave={this.onMouseLeave}>
          <div className="block block__launch">
            <h2>Launch</h2>
          </div>
          <div className="block block__launch">
            <h2>Launch</h2>
          </div>
          <div className="block block__launch">
            <h2>Launch</h2>
          </div>
          <div className="block block__launch">
            <h2>Launch</h2>
          </div>
          <div className="block block__launch">
            <h2>Launch</h2>
          </div>
          <div className="block block__launch">
            <h2>Launch</h2>
          </div>
          <div className="block block__launch">
            <h2>Launch</h2>
          </div>
          <div className="block block__launch">
            <h2>Launch</h2>
          </div>
          <div className="block block__launch">
            <h2>Launch</h2>
          </div>
          <div className="block block__launch">
            <h2>Launch</h2>
          </div>
          <div className="block block__launch">
            <h2>Launch</h2>
          </div>
          <div className="block block__launch">
            <h2>Launch</h2>
          </div>
        </div>
      </NavLink>

      <NavLink exact="exact" to="/exit" className="scroll-right">
        <div className="container container__exit" onMouseEnter={this.onMouseEnterPr} onMouseLeave={this.onMouseLeave}>
          <div className="block block__exit">
            <h2>Exit</h2>
          </div>
          <div className="block block__exit">
            <h2>Exit</h2>
          </div>
          <div className="block block__exit">
            <h2>Exit</h2>
          </div>
          <div className="block block__exit">
            <h2>Exit</h2>
          </div>
          <div className="block block__exit">
            <h2>Exit</h2>
          </div>
          <div className="block block__exit">
            <h2>Exit</h2>
          </div>
          <div className="block block__exit">
            <h2>Exit</h2>
          </div>
          <div className="block block__exit">
            <h2>Exit</h2>
          </div>
          <div className="block block__exit">
            <h2>Exit</h2>
          </div>
          <div className="block block__exit">
            <h2>Exit</h2>
          </div>
          <div className="block block__exit">
            <h2>Exit</h2>
          </div>
          <div className="block block__exit">
            <h2>Exit</h2>
          </div>
          <div className="block block__exit">
            <h2>Exit</h2>
          </div>
        </div>
      </NavLink>

    </div>
  </Tilt> < />);
  }
}

export default NewNewHome
