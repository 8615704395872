import React from 'react'
import styled, {createGlobalStyle} from 'styled-components'
import {NavLink} from 'react-router-dom'
import LogoSVG from '../../assets/images/logo.svg'
import AdiPalace from '../../assets/images/adi-palace.png'

import InfiniteCarousel from 'react-leaf-carousel'

const HomeStyle = createGlobalStyle `
  .sidebar {
    display: none;
  }
  .content .content-head {
    max-width: none;
    width: 100%;
  }
  .content {
    flex: none;
  }
`

const ContentHead = styled.div `
  height: 100%;
  display: flex;
  flex-direction: column;
  & div {
    flex: 1;
  }
`

const Titles = styled.div `
  font-size: 4.2rem;
  line-height: 1.15;
  letter-spacing: -.66px;
  & h1{
    font-size: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    line-height: inherit;
    margin: 0;
  }
  & img {
    max-width: 80px;
  }
`

const HomeBox = styled.div `
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  }
    & > div {
    display: flex;
    flex-basis: calc(50%);
    flex-direction: column;
    &:nth-child(2), :nth-child(4){
      align-items: flex-end;
      text-align: right;
    }
    &:nth-child(3), :nth-child(4){
      justify-content: flex-end;
    }
  }
`

const Logo = styled.div `
  max-width: 100px;
  width: 8rem;
`

const Tiles = styled.div `
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 3;
    font-size: 4rem;
    text-align: center;
    & #infinite-carousel{
      max-height: 33vh;
      margin-top: calc(50% - 33vh);
      & ul {
        margin: 0;
      }
      & .InfiniteCarouselArrowIcon {
        border: solid #e43624;
        border-width: 0 5px 5px 0;
      }
    }
`

const Tile = styled.div `
  background-color: #fff;
  transition: .2s all;
  height: 33vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e43624;
  &:hover {
    cursor: pointer;
    color: #fff;
    background-color: rgba(255,255,255,.12);
    :after {
      content: ''
      position: absolute;
      top: 0;
      width: 100%;
      padding-top: 25%;
      text-align: center;
    }
  }
`

function NewHome() {

  return <div className='content'>
    <HomeStyle/>
    <ContentHead>
      <HomeBox>
        <div>
          <Logo>
            <a href='https://rna-rna.com' rel='noopener noreferrer' target='_blank'>
              <img src={LogoSVG} alt='R.N.A logo'/>
            </a>
          </Logo>
        </div>
        <Titles>
          <img src={AdiPalace} alt="adidas Palace logo"/>
        </Titles>
        <Titles>
          adidas Originals<br/>Digital Marketing
        </Titles>
        <Titles>
          <h1>Palace<br/>Fall / Winter 2020
          </h1>
        </Titles>
      </HomeBox>
    </ContentHead>
    <Tiles>
      <div className="carousel-wrap">
        <InfiniteCarousel breakpoints={[{
              breakpoint: 10000,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            }
          ]} dots={false} swipe={true} showSides={true} sidesOpacity={0.8} sideSize={0.3} slidesToScroll={4} slidesToShow={4} slidesSpacing={15} scrollOnDevice={true}>
          <NavLink exact="exact" to="/tease">
            <Tile>
              Tease
            </Tile>
          </NavLink>
          <NavLink exact="exact" to="/educate">
            <Tile>
              Educate
            </Tile>
          </NavLink>
          <NavLink exact="exact" to="/launch">
            <Tile>
              Launch
            </Tile>
          </NavLink>
          <NavLink exact="exact" to="/exit">
            <Tile>
              Exit
            </Tile>
          </NavLink>
        </InfiniteCarousel>
      </div>
    </Tiles>
  </div>;
}

export default NewHome
