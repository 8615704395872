import React from 'react'
import styled from 'styled-components'
import ProgressiveImage from '../ProgressiveImage'

const Wrap = styled.div `
  display: flex;
  width: 100%;
  & img {
    max-width: 100%;
    max-height: calc(100vh - 18rem);
    height: auto;
  }
  & h3 {
    margin-bottom: 1rem;
  }
`

const Left = styled.div `

`

const Right = styled.div `
  flex: 1;
`
const Gap = styled.div `
  flex: .2
`

function TwoColumn(props) {

  const { title, leftTitle, rightTitle, leftImage, rightImage } = props

  return <div className='content'>
  <h2>{title}</h2>
  <Wrap>
    <Left>
      <h3>{leftTitle}</h3>
      <ProgressiveImage image={leftImage}></ProgressiveImage>
    </Left>
    <Gap />
    <Right>
      <h3>{rightTitle}</h3>
      <ProgressiveImage image={rightImage}></ProgressiveImage>
    </Right>
  </Wrap>
  </div>;
}

export default TwoColumn
