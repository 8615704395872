import React from 'react'
import {Route, Switch, NavLink, Redirect} from 'react-router-dom';
import 'normalize.css'
import './assets/styles/main.sass'
import styled from 'styled-components'
import Collapsible from 'react-collapsible'
import LogoSVG from './assets/images/logo.svg'

import Login from './components/Login'
import Home from './pages/Home'
import NewHome from './pages/NewHome'
import NewNewHome from './pages/NewNewHome'
import Home3 from './pages/Home3'
import CampaignPage from './pages/CampaignPage'

import EducateEPLP from './pages/educate'

// import VideoOverview from './components/VideoOverview'
// import HomePage from './components/HomePage'
// import CategoryLanding from './components/CategoryLanding'
// import ProfileView from './components/ProfileView'
// import FontAlternatives from './components/FontAlternatives'
// import LandingAlternatives from './components/LandingAlternatives'

export const TEASE_ROUTE = '/tease/'
export const EDUCATE_ROUTE = '/educate/'
export const LAUNCH_ROUTE = '/launch/'
export const EXIT_ROUTE = '/exit/'

const Menu = styled.div `
  flex-grow: 1;
  & .links {
    margin: 0 0 2rem 0;
    list-style-position: inside;
    padding: 0;
  }
`

function App() {

  // {/* const location = useLocation()
  //
  // useEffect(() => {
  //   const currentPath = location.pathname
  // }, [location])
  // */}

  return (<div className='wrapper'>
    <div>
      <div className='sidebar'>
        <h1>
          <NavLink exact={true} to="/">Palace FW20</NavLink>
        </h1>
        <Menu>
          <ol className='links'>
            <Collapsible trigger="Tease" transitionTime={100}>
              <li>
                <NavLink exact={true} to={`${TEASE_ROUTE}cp`}>Campaign page</NavLink>
              </li>
            </Collapsible>
          </ol>
          <ol className='links'>
            <Collapsible trigger="Educate" transitionTime={100}>
              <li>
                <NavLink exact={true} to={`${EDUCATE_ROUTE}eplp-igb`}>EPLP-IGB</NavLink>
              </li>
              <li>
                <NavLink exact={true} to={`${EDUCATE_ROUTE}hp`}>HP</NavLink>
              </li>
              <li>
                <NavLink exact={true} to={`${EDUCATE_ROUTE}clp`}>CLP</NavLink>
              </li>
              <li>
                <NavLink exact={true} to={`${EDUCATE_ROUTE}glp`}>GLP</NavLink>
              </li>
              <li>
                <NavLink exact={true} to={`${EDUCATE_ROUTE}plc`}>PLC</NavLink>
              </li>
              <li>
                <NavLink exact={true} to={`${EDUCATE_ROUTE}tc`}>TC</NavLink>
              </li>
              <li>
                <NavLink exact={true} to={`${EDUCATE_ROUTE}glp`}>GLP</NavLink>
              </li>
              <li>
                <NavLink exact={true} to={`${EDUCATE_ROUTE}app`}>App</NavLink>
              </li>
              <li>
                <NavLink exact={true} to={`${EDUCATE_ROUTE}email`}>Email</NavLink>
              </li>
            </Collapsible>
          </ol>
          <ol className='links'>
            {/* open={location.pathname.includes('/tease') ? true : false} */}
            <Collapsible trigger="Launch" transitionTime={100}>
              <li>
                <NavLink exact={true} to={`${LAUNCH_ROUTE}eplp-igb`}>EPLP-IGB</NavLink>
              </li>
              <li>
                <NavLink exact={true} to={`${LAUNCH_ROUTE}email`}>Email</NavLink>
              </li>
              <li>
                <NavLink exact={true} to={`${LAUNCH_ROUTE}hp`}>HP</NavLink>
              </li>
              <li>
                <NavLink exact={true} to={`${LAUNCH_ROUTE}clp`}>CLP</NavLink>
              </li>
              <li>
                <NavLink exact={true} to={`${LAUNCH_ROUTE}glp`}>GLP</NavLink>
              </li>
              <li>
                <NavLink exact={true} to={`${LAUNCH_ROUTE}plc`}>PLC</NavLink>
              </li>
              <li>
                <NavLink exact={true} to={`${LAUNCH_ROUTE}tc`}>TC</NavLink>
              </li>
            </Collapsible>
          </ol>

          <ol className='links' transitionTime={100}>
            <Collapsible trigger="Exit">

            </Collapsible>
          </ol>
        </Menu>
        <div className='logo'>
          <a href='https://rna-rna.com' rel='noopener noreferrer' target='_blank'>
            <img src={LogoSVG} alt='R.N.A logo'/>
          </a>
        </div>
      </div>

      <Switch>
        <Route exact={true} path="/login" component={() => <Login/>}/>
        <Route exact={true} path="/" component={() => <Home3/>}/>
        <Route exact={true} path="/new" component={() => <NewHome/>}/>
        <Route exact={true} path="/new2" component={() => <NewNewHome/>}/>
        <Route exact={true} path="/new3" component={() => <Home/>}/>

        <Redirect exact from='/tease' to={`${TEASE_ROUTE}cp`} />
        <Route exact={true} path={`${TEASE_ROUTE}cp`} component={() => <CampaignPage/>} />

        <Redirect exact from='/educate' to={`${EDUCATE_ROUTE}eplp-igb`} />
        <Route exact={true} path={`${EDUCATE_ROUTE}eplp-igb`} component={() => <EducateEPLP/>} />

        <Route render={() => <p>Not Found</p>}/>
      </Switch>
    </div>
  </div>);
}

export default App;
