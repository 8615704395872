import React from 'react'
import styled, {createGlobalStyle} from 'styled-components'
import {NavLink} from 'react-router-dom'
import LogoSVG from '../../assets/images/logo.svg'
import AdiPalace from '../../assets/images/adi-palace.png'

const HomeStyle = createGlobalStyle `
  .sidebar {
    display: none;
  }
  .content .content-head {
    max-width: none;
    width: 100%;
  }
`

const ContentHead = styled.div `
  height: 100%;
  display: flex;
  flex-direction: column;
  & div {
    flex: 1;
  }
`

const Titles = styled.div `
  font-size: 4.2vh;
  line-height: 1.15;
  letter-spacing: -.66px;
  & h1{
    font-size: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    line-height: inherit;
    margin: 0;
  }
  & img {
    max-width: 60px;
  }
`

const HomeBox = styled.div `
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  }
    & > div {
      display: flex;
      flex: 1;
      &:nth-child(3){
        align-items: flex-end
      }
      &:nth-child(2){
        flex: 3;
      }
      & > div {
        flex: 1;
        &:nth-child(2){
          align-items: flex-end;
          text-align: right;
        }
      }
  }
`

const Logo = styled.div `
  max-width: 100px;
  width: 8rem;
`
const Tiles = styled.div `
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  & > a {
    display: flex;
    flex-basis: calc(50% - 1rem);
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    color: rgb(228,54,36);
    font-size: 4rem;
    transition: .2s all;
    &:hover{
      cursor: pointer;
      background-color: rgba(255,255,255,.12);
      color: #fff;
    }
    &:nth-child(-n+2){
      margin-bottom: 2rem;
    }
    &:nth-child(-2n+3){
      margin-right: 2rem;
    }
    & > div {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
}
`

function Home3() {

  return <div className='content'>
    <HomeStyle/>
    <ContentHead>
      <HomeBox>
        <div>
          <div>
            <Logo>
              <a href='https://rna-rna.com' rel='noopener noreferrer' target='_blank'>
                <img src={LogoSVG} alt='R.N.A logo'/>
              </a>
            </Logo>
          </div>
          <Titles>
            <img src={AdiPalace} alt="adidas Palace logo"/>
          </Titles>
        </div>
        <div>
          <Tiles>
            <NavLink exact="exact" to="/tease">
              Tease
            </NavLink>
            <NavLink exact="exact" to="/educate">
              Educate
            </NavLink>
            <NavLink exact="exact" to="/launch">
              Launch
            </NavLink>
            <NavLink exact="exact" to="/exit">
              Exit
            </NavLink>
          </Tiles>
        </div>
        <div>
          <Titles>
            adidas Originals<br/>Digital Marketing
          </Titles>
          <Titles>
            <h1>Palace<br/>Fall / Winter 2020
            </h1>
          </Titles>
        </div>
      </HomeBox>
    </ContentHead>

  </div>;
}

export default Home3
