import React from 'react'
import styled from 'styled-components'
import Greeting from '../Greeting'

import LogoSVG from '../../assets/images/logo-white.svg'

const LoginWrapper = styled.div`
  background-color: #000;
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LoginForm = styled.div`
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  color: #999;
`

const Form = styled.div`
  position: relative;
`

const TextInput = styled.input`
  width: 40rem;
  max-width: 80vw;
  height: 7.3rem;
  color: transparent;
  text-shadow: 0 0 0 #999;
  padding: 0 2rem;
  line-height: 1.5;
  background: #1a1a1a;
  border: 1px solid #414141;
  border-radius: 12px;
  margin: 6rem 0;
  transition: all 0.30s ease-in-out;
  outline: none;
  &:hover{
    box-shadow: 0 0 3px rgba(255, 255, 255, .6);
    border: 1px solid rgba(255, 255, 255, .6);
  }
`

const SubmitButton = styled.input`
  position: absolute;
  top: 6.1rem;
  width: 8rem;
  margin: 0 0 0 -8rem;
  height: 7.3rem;
  z-index:9;
  background: transparent;
  padding: 0 2rem;
  color: #999;
  border-radius: 0 12px 12px 0;
  border-left: 1px solid #414141;
  border-top: none;
  border-right: none;
  border-bottom: none;
  outline: none;
  transition: background-color .4s;
  &:hover {
    cursor: pointer;
    background-color: rgba(0,0,0,.2);
  }
  &:hover ${TextInput} {
    box-shadow: 0 0 3px rgba(255, 255, 255, .6);
    border: 1px solid rgba(255, 255, 255, .6);
  }
`

const Logo = styled.img`
  width: 100px;
  max-width: 30vw;
  margin: 0 auto 4rem auto;
`

function CategoryLanding() {

  return <LoginWrapper>
    <LoginForm>
      <Logo src={LogoSVG} alt="R.N.A Logo"></Logo>
      <Greeting />,<br></br>please enter your password
      <Form>
        <TextInput type="password" maxLength="13" />
        <SubmitButton type="submit" value="Go" />
      </Form>
    </LoginForm>

  </LoginWrapper>;
}

export default CategoryLanding;
