import React, { useState } from 'react'
import styled from 'styled-components'
import ProgressiveImage from '../../components/ProgressiveImage'
import MobileFrame from '../../components/MobileFrame'
import CategoryLandingImage from '../../assets/images/tease/fw20-palace-cp-statement-d.png'

const Frames = styled.div `
  display: flex;
  flex-direction: row;
  & .framewrapper{
    flex: 1;
  }
`

const Header = styled.div `
  display: flex;
  margin-bottom: 3rem;
  & div {
    flex: 1;
    &:nth-child(2){
      text-align: right;
    }
  }
  & span {
    padding: .45rem 1rem;
    border-radius: 3px;
    &:hover{
      cursor: pointer;
    }
   &.active {
    background-color: #fff;
    color: #e43624;
  }
`


function CategoryLanding() {

  const [desktop, setDesktop] = useState(true)

  const toggleDesktop = () => setDesktop(!desktop);

  return <div className='content'>
    <Header>
      <div>
        <h2>Campaign page</h2>
      </div>
      <div onClick={toggleDesktop}>
        <span className={desktop ? "active" : null}>Desktop</span>
        <span className={desktop ? null : "active"}>Mobile</span>
      </div>
    </Header>
    <Frames>
    { desktop ?
    <div className='framewrapper'>
      <div className='frame'>
        <ProgressiveImage image={CategoryLandingImage}></ProgressiveImage>
      </div>
    </div> :
    <MobileFrame/>
  }
  </Frames>
</div>;
}

export default CategoryLanding;
