import React from 'react'
import styled, {createGlobalStyle} from 'styled-components'
import {NavLink} from 'react-router-dom'
import LogoSVG from '../../assets/images/logo.svg'
import TeaseCP from '../../assets/images/tease/fw20-palace-cp-statement-d.png'

const HomeStyle = createGlobalStyle `
  .sidebar {
    display: none;
  }
  .content .content-head {
    max-width: none;
    width: 100%;
  }
`

const Header = styled.div `
  display: flex;
  flex-direction: row;
  & div {
    flex: 1;
  }
`

const Logo = styled.div `
  max-width: 100px;
  width: 8rem;
`

const PhaseColumns = styled.div `
  display: flex;
  flex-direction: row;
  & div {
    flex: 1;
    margin-right: 1.5rem;
  }
`

const Preview = styled.div `
  display: flex;
  position: relative;
  width: 100%;
  background: url('${props => props.bg}') #fff;
  background-size: cover;
  margin-top: 1rem;
  padding-top: 56.25%;
  width: 100%;
  &:hover {
    cursor: pointer;
    :after {
      content: '${props => props.title}';
      position: absolute;
      top: 0;
      width: 100%;
      padding-top: 25%;
      text-align: center;
    }
    :before {
      content:'';
      color: #fff;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #e43624;
      opacity: .84;
    }
  }
  /* &:not(:first-of-type){
    margin-top: 2rem;
  } */
`

function Home() {

  return <div className='content'>
    <HomeStyle/>
    <div className='content-head'>
      <Header>
        <div>
          <h2>Palace FW20</h2>
        </div>
        <Logo>
          <a href='https://rna-rna.com' rel='noopener noreferrer' target='_blank'>
            <img src={LogoSVG} alt='R.N.A logo'/>
          </a>
        </Logo>
      </Header>
      <p>Lorem ipsum</p>
      <PhaseColumns>
        <div>
          <h3>Tease</h3>
          <NavLink exact={true} to="/tease/cp">
            <Preview bg={TeaseCP} title="Campaign page"/>
          </NavLink>
        </div>
        <div>
          <h3>Educate</h3>
          <Preview bg={TeaseCP}/>
          <Preview bg={TeaseCP}/>
          <Preview bg={TeaseCP}/>
          <Preview bg={TeaseCP}/>
          <Preview bg={TeaseCP}/>
          <Preview bg={TeaseCP}/>
          <Preview bg={TeaseCP}/>
          <Preview bg={TeaseCP}/>
        </div>
        <div>
          <h3>Launch</h3>
          <Preview bg={TeaseCP}/>
          <Preview bg={TeaseCP}/>
          <Preview bg={TeaseCP}/>
          <Preview bg={TeaseCP}/>
          <Preview bg={TeaseCP}/>
          <Preview bg={TeaseCP}/>
          <Preview bg={TeaseCP}/>
          <Preview bg={TeaseCP}/>
        </div>
        <div>
          <h3>Exit</h3>
          <Preview bg={TeaseCP}/>
        </div>
      </PhaseColumns>
    </div>
  </div>;
}

export default Home;
