import React from 'react'

export default class Greeting extends React.Component {

    state = {
      hour: null
    }

    componentDidMount() {
      this.getHour()
    }

    getHour = () => {
     const date = new Date();
     const hour = date.getHours()
     this.setState({
        hour
     });
    }

    render(){
      const {hour} = this.state;
      const greetingTime = (
        hour > 3 && hour < 12 ? 'Good morning' :
        hour < 18 ? 'Good afternoon' :
        'Good evening'
      );

      return (<>
        {greetingTime}
        </>);
    }
  }
