import React from 'react'
import TwoColumn from '../../components/TwoColumn'
import Desktop from '../../assets/images/educate/fw20-palace-eplp-igb-d.jpg'
import Mobile from '../../assets/images/educate/fw20-palace-eplp-igb-m.jpg'

function EducateEPLP() {
  return <TwoColumn title="EPLP-IGB" leftTitle="Desktop" leftImage={Desktop} rightTitle="Mobile" rightImage={Mobile} />;
}

export default EducateEPLP
